import { NavLink } from "react-router-dom";
import { sosIcon } from "../../assets/images";
import { useState } from "react";

const SOSbtn = (props) => {
  // const [position, setPosition] = useState({ lat: null, lng: null });

  // const HandleSubmit = () => {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     setPosition({
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     });
  //   });
  // };
  // console.log(position, "lat long");
  return (
    <NavLink
      to={props.hrefLink}
      // onClick={HandleSubmit}
      className="flex w-full items-center justify-center gap-3 bg-red p-5 pb-8 rounded-t-[43px] border-4 border-white border-b-0"
    >
      <img src={sosIcon} alt="sos" className="" />
      <div className="text-white fw-bold">{props.sostitle}</div>
    </NavLink>
  );
};

export default SOSbtn;

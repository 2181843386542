import { request } from "../utilities/request";

export const GetAllSquadAPI = async (body) => {
  // console.log(body, "ddjkjn");
  console.log(body, "jhd");
  const response = await request({
    url: "api/squad/lists",
    method: "POST",
    body,
  });
  return response;
};

export const CreateSquadAPI = async (body) => {
  // console.log(body, "ddjkjn");

  const response = await request({
    url: "api/squad/create",
    method: "POST",
    body,
  });
  return response;
};
export const ShareSquadAPI = async (id) => {
  console.log(id, "ddjkjn");

  const response = await request({
    url: `api/get-squad-code/${id.id}`,
    method: "GET",
  });
  return response;
};

export const JoinSquadAPI = async (body) => {
  // console.log(body, "ddjkjn");

  const response = await request({
    url: "api/squad/join-squad-by-code",
    method: "POST",
    body,
  });
  return response;
};
import BackBtn from "../Components/BackBtn/BackBtn";
import FormBtn from "../Components/FormBtn/FormBtn";
import { backButton, squadIcon } from "../assets/images";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import "../App.css";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { CreateSquadAPI } from "../api/squad";
import { useAuthStore } from "../store/auth";
import { useNavigate } from "react-router";
import ButtonLoader from "../Components/Loader/ButtonLoader";
import * as Yup from "yup";
import Notiflix from "notiflix";

const currencies = [
  {
    value: "Father",
    label: "Padre",
  },
  {
    value: "Mother",
    label: "Madre",
  },
  {
    value: "Son",
    label: "Hijo",
  },
  {
    value: "Friend",
    label: "Amiga/Amigo",
  },
  {
    value: "Other",
    label: "Otra/Otro",
  },
];

const CreateSquad = () => {
  const { user } = useAuthStore();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(CreateSquadAPI, {
    onSuccess: (data) => {
      if (data?.status) {
        Notiflix.Notify.success("Plantilla creada con éxito");
        console.log(data, "response");

        navigate("/my-squad");
      }
    },
    onError: (data) => {
      Notiflix.Notify.failure("¡Algo salió mal! Por favor, inténtelo de nuevo más tarde");
      console.log(data, "error");
      // navigate("/error");
    },
  });
  const validationSchema = Yup.object().shape({
    squadName: Yup.string().required("Se requiere el nombre del escuadrón"),

    role: Yup.string().required("Tu rol es requerido"),
  });
  const { handleChange, handleBlur, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: {
        squadName: "",
        role: "",
      },
      validationSchema,

      // validationSchema: Yup.object().shape({
      //   username: yup.string().required("Field is required"),
      // }),
      onSubmit: (values) => {
        console.log(values);
        mutate({
          uid: user?.uid,
          squadName: values?.squadName,
          members: [
            {
              user: user?.uid,
              role: values?.role,
            },
          ],
          owner: {
            user: user?.uid,
            role: values?.role,
          },
        });
      },
    });

  return (
    <form onSubmit={handleSubmit} className="container relative">
      <BackBtn
        backName="Crear un equipo"
        backLink="/my-squad"
        backImg={backButton}
        backDesc="No te preocupes, puedes editarlo más tarde desde la configuración. así es como aparecerás en Ursafe"
      />
      <div className="mx-6 pt-44  h-screen flex flex-col justify-between pb-5 custom_design">
        <div className=" pb-4">
          <div className="mb-5">
            <TextField
              value={values.squadName}
              id="outlined-multiline-flexible"
              name="squadName"
              label="Introduce el nombre del equipo"
              placeholder="Introduce el nombre del equipo"
              onChange={handleChange}
              onBlur={handleBlur}
              focused
              className="w-full"
            />
            {touched.squadName && errors.squadName ? (
              <div className="text-red mt-1 text-[12px] pl-3">
                {errors.squadName}
              </div>
            ) : null}
          </div>
          <div className="select_field">
            <TextField
              value={values.role}
              id="outlined-select-currency"
              select
              name="role"
              onBlur={handleBlur}
              onChange={handleChange}
              label="Selecciona tu rol"
              defaultValue="Father"
              className="w-full"
            >
              {currencies.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </TextField>
            {touched.role && errors.role ? (
              <div className="text-red mt-1 text-[12px] pl-3">
                {errors.role}
              </div>
            ) : null}
          </div>
        </div>
        {isLoading ? (
          <button
            type="submit"
            className="w-full lg:w-80 flex items-center p-2 rounded-[29px] bg-[#7258F9] gap-2 mx-auto"
          >
            <ButtonLoader />
          </button>
        ) : (
          <FormBtn
            formBtnLable="Crear equipo"
            btnIcon={squadIcon}
            // redirectPage={() => (window.location.href = "/share-code")}
          />
        )}
      </div>
    </form>
  );
};

export default CreateSquad;

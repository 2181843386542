import React from "react";

const Error404 = () => {
  return (
    <>
      <div className="text-[100px] w-screen h-screen flex flex-col justify-center items-center">
        404
        <div className="text-[30px] text-center">¡Algo salió mal!</div>
      </div>
    </>
  );
};

export default Error404;

import BackBtn from "../Components/BackBtn/BackBtn";
import FormBtn from "../Components/FormBtn/FormBtn";
import SecurityMsg from "../Components/SecurityMsg/SecurityMsg";
import { followIcon, menuButton } from "../assets/images";
import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { useAuthStore } from "../store/auth";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { FollowMestopAPI } from "../api/follow_me";
import FollowMap from "../Components/FollowMap/FollowMap";
import ButtonLoader from "../Components/Loader/ButtonLoader";
import { UpdateLocationAPI } from "../api/updateLocation";
import Notiflix from "notiflix";

const libraries = ["places"];

const FollowRide = () => {
  const { cordinates, user, bookingData, setCordinates } = useAuthStore();
  const navigate = useNavigate();
  // const [location, setLocation] = useState(null);

  //on window close stop follow me function
  function useBeforeUnload(callback) {
    useEffect(() => {
      window.addEventListener("beforeunload", callback);
      return () => {
        window.removeEventListener("beforeunload", callback);
      };
    }, [callback]);
  }

  const { mutate } = useMutation(UpdateLocationAPI, {
    onSuccess: (data) => {
      if (data?.status) {
        setCordinates(data?.data);
      }
    },
    onError: (data) => {
      Notiflix.Notify.failure(
        "No estamos obteniendo sus datos de ubicación, intente restablecer el permiso de su navegador"
      );
      console.log(data, "Error");
    },
  });

  // useEffect(() => {
  //   const updateLocation = () => {
  //     if ("geolocation" in navigator) {
  //       navigator.geolocation.getCurrentPosition(
  //         (geoPosition) => {
  //           const { latitude, longitude } = geoPosition.coords;
  //           const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //           const data = {
  //             uid: user?.uid,
  //             latitude,
  //             longitude,
  //             location_update_at: geoPosition?.timestamp,
  //             location_timezone: timezone,
  //           };

  //           console.log(geoPosition.coords, "geoPosition");

  //           // Update state to trigger re-render
  //           setLocation(data);

  //           // Send data to backend or mutate function
  //           mutate(data);
  //         },
  //         (error) => {
  //           console.error("Geolocation error:", error);

  //           const errorMessage =
  //             error.code === error.PERMISSION_DENIED
  //               ? "Permiso de ubicación denegado. Verifique los ajustes del navegador."
  //               : error.code === error.POSITION_UNAVAILABLE
  //               ? "Ubicación no disponible. Inténtelo de nuevo más tarde."
  //               : error.code === error.TIMEOUT
  //               ? "El tiempo de espera para obtener la ubicación se agotó."
  //               : "Error desconocido al obtener la ubicación.";

  //           Notiflix.Notify.failure(errorMessage);
  //         }
  //       );
  //     } else {
  //       Notiflix.Notify.failure(
  //         "La geolocalización no está disponible en su navegador"
  //       );
  //       console.log("Geolocation is not available in your browser.");
  //     }
  //   };

  //   // Call the location update immediately on mount
  //   updateLocation();

  //   // Set interval to call the updateLocation function every 30 seconds
  //   const intervalId = setInterval(updateLocation, 30000);

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [user, mutate]);

  useEffect(() => {
    let intervalId;

    const updateLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (geoPosition) => {
            const { latitude, longitude } = geoPosition.coords;
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const data = {
              uid: user?.uid,
              latitude,
              longitude,
              location_update_at: geoPosition?.timestamp,
              location_timezone: timezone,
            };

            console.log("geoPosition", geoPosition.coords);

            // Update state to trigger re-render
            // setLocation(data);

            // Send data to backend or mutate function
            mutate(data);
          },
          (error) => {
            console.error("Geolocation error:", error);

            const errorMessage =
              error.code === error.PERMISSION_DENIED
                ? "Permiso de ubicación denegado. Verifique los ajustes del navegador."
                : error.code === error.POSITION_UNAVAILABLE
                ? "Ubicación no disponible. Inténtelo de nuevo más tarde."
                : error.code === error.TIMEOUT
                ? "El tiempo de espera para obtener la ubicación se agotó."
                : "Error desconocido al obtener la ubicación.";

            Notiflix.Notify.failure(errorMessage);
          },
          {
            enableHighAccuracy: true, // Use GPS for more accurate location
            timeout: 5000, // Timeout after 5 seconds
            maximumAge: 10000, // Cache location for up to 10 seconds
          }
        );
      } else {
        Notiflix.Notify.failure(
          "La geolocalización no está disponible en su navegador"
        );
        console.log("Geolocation is not available in your browser.");
      }
    };

    // Call updateLocation immediately on mount
    updateLocation();

    // Set up interval to call updateLocation every 30 seconds
    intervalId = setInterval(updateLocation, 30000);

    // Cleanup interval on component unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [user, mutate]);

  useBeforeUnload(() => {
    FollowMestop({
      final_destination: user?.destination,
      uid: user?.uid,
      latitude:
        // 23.071298202877863,
        cordinates?.location?._latitude.toString(),
      longitude:
        // 72.51780074021997,
        cordinates?.location?._longitude.toString(),
    });
  });

  const { mutate: FollowMestop, isLoading } = useMutation(FollowMestopAPI, {
    onSuccess: (data) => {
      if (data?.success) {
        console.log(data.data, "Response");
        navigate("/home");
      }
    },
    onError: (data) => {
      console.log(data?.message, "something went wrong");
    },
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBiLNiGvUl_DnTA6M9uG-4gAbGLwb7rtQc",
    libraries,
  });

  if (loadError) {
    return <div>Error al cargar mapas</div>;
  }

  if (!isLoaded) {
    return <div>Cargando mapas</div>;
  }

  const handleStop = () => {
    const data = {
      final_destination: user?.destination,
      uid: user?.uid,
      latitude:
        // 23.071298202877863,
        cordinates?.location?._latitude.toString(),
      longitude:
        // 72.51780074021997,
        cordinates?.location?._longitude.toString(),
    };

    FollowMestop(data);
  };

  return (
    <div className="container relative white_bg_effect">
      <BackBtn
        backName="Sigue mi viaje"
        backLink="/home"
        backImg={menuButton}
      />
      {/* <GoogleMapSec /> */}
      <div className="google_map container">
        <FollowMap
          latitude={cordinates?.location?._latitude}
          longitude={cordinates?.location?._longitude}
          directionsLatitude={cordinates?.locationDestination?._latitude}
          directionsLongitude={cordinates?.locationDestination?._longitude}
        />
      </div>
      <div className="absolute left-0 right-0 bottom-0 lg:mx-10 z-10 pb-10 mx-6">
        <SecurityMsg alertMsg="Su viaje ahora es seguido por la compañía de seguridad" />

        {isLoading ? (
          <button
            type="submit"
            className="w-full lg:w-80 flex items-center p-2 rounded-[29px] bg-[#7258F9] gap-2 mx-auto"
          >
            <ButtonLoader />
          </button>
        ) : (
          <button
            type="submit"
            className="w-full lg:w-80 flex items-center p-2 rounded-[29px] bg-[#7258F9] gap-2 mx-auto"
            onClick={handleStop}
          >
            <div className=" w-12 h-12 rounded-full bg-darkBlue flex items-center justify-center flex-shrink-0">
              <img src={followIcon} alt="follow me" className="" />
            </div>
            <div className="text-white w-full text-center">Dejar de seguir</div>
          </button>
        )}
      </div>
    </div>
  );
};

export default FollowRide;

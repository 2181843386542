import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 0,
    },
  },
});
const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AgoraRTCProvider client={client}>
        <App />
      </AgoraRTCProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();

import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BackBtn from "../../Components/BackBtn/BackBtn";
import { RegisterAPI } from "../../api/auth";
import { loaderGif, loaderIcon, menuButton } from "../../assets/images";
import { useAuthStore } from "../../store/auth";
import LoaderTitle from "../LoaderTitle/LoaderTitle";
import "./style.css";
import Notiflix from "notiflix";

const LoaderSection = () => {
  const navigate = useNavigate();
  const { setUser, contactDetail, securityId } = useAuthStore();

  // const [position, setPosition] = useState({
  //   lat: "",
  //   lng: "",
  // });
  const { isLoading, mutate } = useMutation(RegisterAPI, {
    onSuccess: (data) => {
      if (data?.status) {
        if (data?.isemail) {
          Notiflix.Notify.failure(
            "El correo electrónico ya existe, utilice un correo electrónico diferente"
          );
        } else {
          setUser(data?.data);
          navigate("/home");
        }
      }
    },
    onError: (data) => {
      console.log(data, "Error");
      navigate("/error");
    },
  });

  useEffect(() => {
    // navigator.geolocation.getCurrentPosition(function (geoPosition) {
    //   const { latitude, longitude } = geoPosition.coords;

    //   setPosition({
    //     lat: latitude,
    //     lng: longitude,
    //   });
    //   console.log(position, "position Loader");
    // });

    setFieldValue("countryCode", contactDetail?.code);
    setFieldValue("phoneNumber", contactDetail?.phone);
  }, []);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Se requiere el primer nombre"),
    lastName: Yup.string().required("el apellido es obligatorio"),
    // phoneNumber: Yup.string()
    //   .required()
    //   .test("isValidNumber", "Invalid mobile number", function (value) {
    //     // Use PhoneNumberValidator to check if the number is valid
    //     const iso = values.countryCode?.code;
    //     console.log(value, iso, "dlkndfjvfdjlvnb");
    //     const isValid = PhoneNumberValidator(value, iso);
    //     console.log(isValid, "isValid");
    //     if (isValid) {
    //       return true; // The number is valid
    //     } else {
    //       return this.createError({
    //         path: "phoneNumber",
    //         message: "You have entered incorrect mobile number.",
    //       });
    //     }
    //   }),
    // countryCode: Yup.object().shape({
    //   code: Yup.string().required("Please choose a country code"),
    //   label: Yup.string().required("Please choose a country code"),
    //   phone: Yup.string().required("Please choose a country code"),
    // }),
    email: Yup.string().email().required("correo electronico es requerido"),
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      countryCode: "",
    },
    validationSchema,

    onSubmit: (values) => {
      // if (!position.lat || !position.lng) {
      //   Notiflix.Notify.failure(
      //     "Geolocation information is required. Please allow access to your location permission in your browser setting."
      //   );

      //   return;
      // }

      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: `+${values.countryCode}${values.phoneNumber}`,
        email: values.email,
        latitude: contactDetail?.latitude,
        longitude: contactDetail?.longitude,
        security_company_id: securityId?.id,
        security_contact_number: securityId?.number,
      };

      mutate(data);
      // console.log(data, "values");
    },
  });

  return (
    <div className="loader_background">
      {isLoading ? (
        <div className="container">
          <div className="relative h-dvh grid place-items-center">
            <div className="relative">
              <img src={loaderIcon} alt="Imagen del cargador" className="" />
              <img
                src={loaderGif}
                alt="cargador gif"
                className="absolute w-28 h-28 left-2 right-0 mx-auto top-[-32px]"
              />
            </div>
            <div className="absolute left-0 right-0 mx-auto bottom-24">
              <LoaderTitle loadertitle="Autenticación..." loaderdesc="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <form onSubmit={handleSubmit} className="container relative">
            <BackBtn
              backName="Registro"
              backLink={`/${securityId}`}
              backImg={menuButton}
              backDesc=""
            />
            <div className="mx-6 pt-44 h-screen flex flex-col justify-between pb-5 custom_design">
              <div className="pb-4">
                {/* <div className="select_field mb-10">
                  <Autocomplete
                    id="country-customized-option-demo"
                    // disableCloseOnSelect
                    options={countries}
                    name="countryCode"
                    onChange={(e, newValue) => {
                      console.log(newValue, "newValue");
                      setFieldValue("countryCode.code", newValue?.code);
                      setFieldValue("countryCode.label", newValue?.label);
                      setFieldValue("countryCode.phone", newValue?.phone);
                    }}
                    autoHighlight
                    disableClearable
                    getOptionLabel={(option) =>
                      `${option.label} +${option.phone}`
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.label} +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country code"
                        name="countryCode"
                        value={values.countryCode.phone}
                      />
                    )}
                  />
                  {touched.countryCode && errors.countryCode ? (
                    <div className="text-red mt-1 text-[12px] pl-3">
                      {errors.countryCode?.phone}
                    </div>
                  ) : null}
                </div> */}

                <div className="mb-10">
                  <TextField
                    disabled
                    label="Código de país"
                    name="countryCode"
                    value={`+${values.countryCode}`}
                    type="tel"
                    id="outlined-multiline-flexible"
                    // placeholder="Enter PhoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    focused
                    className="w-full bg-[#ccc] rounded-2xl"
                  />
                  {touched.phoneNumber && errors.phoneNumber ? (
                    <div className="text-red mt-1 text-[12px] pl-3">
                      {errors.phoneNumber}
                    </div>
                  ) : null}
                </div>

                <div className="mb-10">
                  <TextField
                    disabled
                    value={values.phoneNumber}
                    type="tel"
                    id="outlined-multiline-flexible"
                    name="phoneNumber"
                    label="Ingresa número telefónico"
                    placeholder="Ingresa número telefónico"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    focused
                    className="w-full bg-[#ccc] rounded-2xl"
                  />
                  {touched.phoneNumber && errors.phoneNumber ? (
                    <div className="text-red mt-1 text-[12px] pl-3">
                      {errors.phoneNumber}
                    </div>
                  ) : null}
                </div>

                <div className="mb-10">
                  <TextField
                    value={values.firstName}
                    id="outlined-multiline-flexible"
                    name="firstName"
                    label="Introduzca el nombre"
                    // placeholder="Enter FirstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    focused
                    className="w-full muiComponentClass"
                  />
                  {touched.firstName && errors.firstName ? (
                    <div className="text-red mt-1 text-[12px] pl-3">
                      {errors.firstName}
                    </div>
                  ) : null}
                </div>
                <div className="mb-10">
                  <TextField
                    value={values.lastName}
                    id="outlined-multiline-flexible"
                    name="lastName"
                    label="Introduzca el apellido"
                    // placeholder="Enter lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    focused
                    className="w-full"
                  />
                  {touched.lastName && errors.lastName ? (
                    <div className="text-red mt-1 text-[12px] pl-3">
                      {errors.lastName}
                    </div>
                  ) : null}
                </div>
                <div className="mb-10">
                  <TextField
                    value={values.email}
                    id="outlined-multiline-flexible"
                    name="email"
                    label="Ingrese correo electrónico"
                    // placeholder="Enter Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    focused
                    className="w-full"
                  />
                  {touched.email && errors.email ? (
                    <div className="text-red mt-1 text-[12px] pl-3">
                      {errors.email}
                    </div>
                  ) : null}
                </div>
              </div>

              <button
                type="submit"
                className="w-full lg:w-80 flex items-center p-2 py-3 rounded-[29px] bg-[#7258F9] gap-2 mx-auto"
              >
                <div className="text-white w-full text-center">Registro</div>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default LoaderSection;

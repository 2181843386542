import React from "react";
import { Oval } from "react-loader-spinner";

const ButtonLoader = () => {
  return (
    <div className="flex justify-center items-center w-full">
      <Oval
        visible={true}
        height="40"
        width="40"
        color="#ffffff"
        // secondaryColor="black"
        ariaLabel="oval-loading"
        // strokeWidth={6.4}
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default ButtonLoader;

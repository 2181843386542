import { request } from "../utilities/request";

export const FollowMeAPI = async (body) => {
  // console.log(body, "ddjkjn");
  console.log(body, "jhd");
  const response = await request({
    url: "api/follow-me/send-invitation",
    method: "POST",
    body,
  });
  return response;
};

export const FollowMestopAPI = async (body) => {
  const response = await request({
    url: "api/follow-me/stop",
    method: "POST",
    body,
  });
  return response;
};
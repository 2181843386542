import { create } from "zustand";
import { persist } from "zustand/middleware";
// import { AuthState } from "../types";

export const useAuthStore = create()(
  persist(
    (set) => ({
      Isdark: false,
      user: null,
      securityId: null,
      data: null,
      cordinates: null,
      bookingData: null,
      contactDetail: null,
      setBookingData: (bookingData) =>
        set(() => ({ bookingData: bookingData })),
      setUser: (user) => set(() => ({ user: user })),
      setContactDetail: (contactDetail) =>
        set(() => ({ contactDetail: contactDetail })),
      setCordinates: (cordinates) => set(() => ({ cordinates: cordinates })),
      setData: (data) => set(() => ({ data: data })),
      setIsdark: (Isdark) => set(() => ({ Isdark: Isdark })),
      setSecurityId: (securityId) => set(() => ({ securityId: securityId })),
      removeAll: () =>
        set(() => ({
          Isdark: false,
          user: null,
          securityId: null,
          data: null,
          cordinates: null,
          bookingData: null,
          contactDetail: null,
        })),
    }),
    {
      name: "auth",
      getStorage: () => {
        let rememberMe = localStorage.getItem("rememberMe");
        if (rememberMe !== null) {
          rememberMe = JSON.parse(rememberMe);
        }
        if (typeof rememberMe === "boolean" && !rememberMe) {
          return sessionStorage;
        }
        return sessionStorage;
      },
    }
  )
);

import { NavLink } from "react-router-dom";
import BackBtn from "../Components/BackBtn/BackBtn";
import SquadItem from "../Components/SquadItem/SquadItem";
import { backButton } from "../assets/images";



const MySquad = () => {
  return (
    <div className="container relative">
      <BackBtn
        backName="mi equipo"
        backLink="/home"
        backImg={backButton}
        backDesc=""
      />
      <div className="mx-6 pt-44">
        <div className="my-4 h-[calc(100vh-290px)] overflow-auto">
          <SquadItem />
        </div>
        <div className="grid grid-cols-2 items-center gap-2">
          <NavLink
            to="/create-squad"
            className="text-center border border-darkBlue p-4 rounded-full text-[#191217] font-bold"
          >
            Crear equipo
          </NavLink>
          <NavLink
            to="/otp-verify"
            className="text-center border border-blue bg-blue p-4 rounded-full text-white font-bold"
          >
            Únete al equipo
          </NavLink>
        </div>
      </div>
    </div>
  );
};
export default MySquad;

import BackBtn from "../Components/BackBtn/BackBtn";
import { backButton, squadIcon } from "../assets/images";
import FormBtn from "../Components/FormBtn/FormBtn";
// import TextField from "@mui/material/TextField";
import OtpInput from "react-otp-input";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { JoinSquadAPI } from "../api/squad";
import { useAuthStore } from "../store/auth";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ButtonLoader from "../Components/Loader/ButtonLoader";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Notiflix from 'notiflix';

const currencies = [
  {
    value: "Father",
    label: "Padre",
  },
  {
    value: "Mother",
    label: "Madre",
  },
  {
    value: "Son",
    label: "Hijo",
  },
  {
    value: "Friend",
    label: "Amiga/Amigo",
  },
  {
    value: "Other",
    label: "Otra/Otro",
  },
];

const OtpVerify = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { mutate, isLoading } = useMutation(JoinSquadAPI, {
    onSuccess: (data) => {
      Notiflix.Notify.success('Unirse al equipo con éxito');
      navigate("/my-squad");
    },
    onError: (data) => {
      Notiflix.Notify.failure('¡Algo salió mal! Por favor, inténtelo de nuevo más tarde');
      console.log(data);
    },
  });

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required("Se requiere código de unión"),
    role: Yup.string().required("Tu rol es requerido"),
  });
  const { handleChange, handleBlur, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: {
        otp: "",
        role: "",
      },
      validationSchema,
      // validationSchema: Yup.object().shape({
      //   username: Validation.username,
      // }),
      onSubmit: (values) => {
        console.log(values);
        const data = {
          code: values.otp,
          uid: user.uid,
          relation: values.role,
        };
        mutate(data);
      },
    });

  return (
    <form onSubmit={handleSubmit} className="container relative">
      <BackBtn
        backLink="/my-squad"
        backImg={backButton}
        backName="Ingrese el código de invitación"
        backDesc="Obtén el código de la persona que crea tu equipo"
      />
      <div className="mx-6 pt-44 h-screen flex flex-col justify-between pb-5">
        <div className="pb-4 inline-flex flex-col items-center justify-center gap-4 otp_verify">
          <div className="select_field w-full max-w-[375px]">
            <TextField
              value={values.role}
              id="outlined-error"
              // id={`${
              //   touched.role && errors.role
              //     ? "outlined-error"
              //     : "outlined-select-currency"
              // }`}
              select
              name="role"
              onBlur={handleBlur}
              onChange={handleChange}
              label="Selecciona tu rol"
              defaultValue="Father"
              className=" w-full rounded-md"
            >
              {currencies.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </TextField>
            {touched.role && errors.role ? (
              <div className="text-red mt-2 text-[12px] pl-3">
                {errors.role}
              </div>
            ) : null}
          </div>
          <OtpInput
            value={values.otp}
            onChange={(otp) =>
              handleChange({ target: { name: "otp", value: otp } })
            }
            numInputs={6}
            inputType="text"
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "40px",
              height: "56px",
              margin: "0 0.5rem",
              padding: "0.5rem",
              borderRadius: "16px",
              border: "1px solid #F4F2FF",
              textAlign: "center",
              fontSize: "16px",
              backgroundColor: "#F4F2FF",
              outline: "none",
              alignItems: "start",
            }}
          />
          {touched.otp && errors.otp ? (
            <div className="text-red -mt-5 text-[12px] pl-3">{errors.otp}</div>
          ) : null}
        </div>
        {isLoading ? (
          <button
            type="submit"
            className="w-full lg:w-80 flex items-center p-2 rounded-[29px] bg-[#7258F9] gap-2 mx-auto"
          >
            <ButtonLoader />
          </button>
        ) : (
          <FormBtn formBtnLable="Únete al equipo" btnIcon={squadIcon} />
        )}
      </div>
    </form>
  );
};

export default OtpVerify;

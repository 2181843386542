import SOSbtn from "../Components/SOSbtn/SOSbtn";
import SecurityMsg from "../Components/SecurityMsg/SecurityMsg";
import BackBtn from "../Components/BackBtn/BackBtn";
// import GoogleMapSec from "../Components/GoogleMap/GoogleMap";
import { menuButton, backButton } from "../assets/images";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { useAuthStore } from "../store/auth";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SOSNotifyAPI, SOSstopAPI } from "../api/sos";
import ButtonLoader from "../Components/Loader/ButtonLoader";
import { useNavigate } from "react-router-dom";
import LocationIcon from "../assets/images/redicon.png";
import UpArrow from "../assets/images/upArrow.gif";
import {
  LocalUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import { LiveStreamApi } from "../api/sos";
import FollowMap from "../Components/FollowMap/FollowMap";
// import { Modal } from 'react-responsive-modal';
import NavigateIcon from "../assets/images/navigate.svg";
import Notiflix from "notiflix";

const libraries = ["places"];
const mapContainerStyle = {
  // max-width: '100vw',
  height: "100vh",

  // height:'100dvh',
};

const googleStyle = [
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#d3d3d3",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        color: "#808080",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#b3b3b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        weight: 1.8,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d7d7d7",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ebebeb",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#a7a7a7",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#efefef",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#696969",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#737373",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d6d6d6",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {},
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
];

const Emergency = () => {
  const [videoData, setVideoData] = useState();

  const { cordinates, user, securityId } = useAuthStore();
  const navigate = useNavigate();
  const [position, setPosition] = useState({
    lat: cordinates?.location?._latitude,
    lng: cordinates?.location?._longitude,
  });
  const [calling, setCalling] = useState(false);

  const appId = "c546ffb5e5bc4b0a8b4d278e7111d037";
  const channel = videoData?.channelName;

  const token = videoData?.verifyToken;

  useJoin(
    { appid: appId, channel: channel, token: token ? token : null },
    calling
  );

  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const isConnected = useIsConnected();
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localCameraTrack, localMicrophoneTrack]);

  const [data, setData] = useState({});

  const [show, setShow] = useState(false);
  const [sosId, setSosId] = useState("");

  const [nav, setNav] = useState(false);
  const [direction, setDirection] = useState({
    lat: user?.coordinates?.latitude,
    lng: user?.coordinates?.longitude,
  });
  const [policeData, setPoliceData] = useState(true);
  const handleDirection = (lat, lng) => {
    console.log(lat);
    console.log(lng);
    setDirection({
      lat: lat,
      lng: lng,
    });
    setNav(true);
  };

  const { mutate } = useMutation(LiveStreamApi, {
    onSuccess: (data) => {
      setVideoData(data);
      setCalling(true);
    },
    onError: (data) => {
      console.log(data, "LiveStreamApi Error");
    },
  });

  const {} = useQuery(
    ["SOSNotifyAPI"],
    () => {
      return SOSNotifyAPI({
        uid: cordinates.uid,
        latitude: cordinates?.location?._latitude,
        longitude: cordinates?.location?._longitude,
        is_init_live_stream_successfully: "",
        type: "",
        security_company_id: securityId?.id,
      });
    },
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        console.log(data, "sts");
        if (data?.success) {
          Notiflix.Notify.success("SOS comenzó exitosamente");
          setData(data.data);
          setSosId(data?.data?.sosId);

          const Streamdata = {
            userId: user.uid,
            email: user.email,
            phone: user.phoneNumber,
            eventId: 897456,
            channelName: user?.uid,
            latitude: cordinates?.location?._latitude,
            longitude: cordinates?.location?._longitude,
            sosId: data?.data?.sosId,
          };
          navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then(() => {
              mutate(Streamdata);
              // Permission granted, proceed with accessing camera and microphone
            })
            .catch((error) => {
              // Permission denied, show alert
              alert("Se requiere permiso para la cámara y el micrófono");
            });
        }
      },
      onError: (data) => {
        Notiflix.Notify.failure(
          "¡Algo salió mal! Por favor, inténtelo de nuevo más tarde"
        );
        navigate("/home");
        console.log(data, "Error");
      },
    }
  );

  const handlePolice = () => {
    setShow(true);
    setPoliceData(true);
  };
  const handleLgbtq = () => {
    setShow(true);
    setPoliceData(false);
  };

  //On window close stop sos function
  function useBeforeUnload(callback) {
    useEffect(() => {
      window.addEventListener("beforeunload", callback);
      return () => {
        window.removeEventListener("beforeunload", callback);
      };
    }, [callback]);
  }

  useBeforeUnload(() => {
    StopSOS({
      final_destination: user?.destination,
      uid: user?.uid,
      latitude:
        // 23.071298202877863,
        cordinates?.location?._latitude.toString(),
      longitude:
        // 72.51780074021997,
        cordinates?.location?._longitude.toString(),
      // type:2
    });
  });

  const { mutate: StopSOS, isLoading } = useMutation(SOSstopAPI, {
    onSuccess: (data) => {
      if (data?.success) {
        Notiflix.Notify.success("SOS se detuvo con éxito");
        console.log(data.data, "Response");
        navigate("/home");
      }
    },
    onError: (data) => {
      Notiflix.Notify.failure(
        "¡Algo salió mal! Por favor, inténtelo de nuevo más tarde"
      );
      console.log(data?.message, "something went wrong");
    },
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDEMcchGaq5u9_6pRylftzvliVrm2KW0Qw",
    libraries,
  });

  if (loadError) {
    return <div>Error al cargar mapas</div>;
  }

  if (!isLoaded) {
    return <div>Cargando mapas</div>;
  }

  return (
    <>
      {nav ? (
        <div className="container">
          <div
            onClick={() => setNav(false)}
            className="relative cursor-pointer"
          >
            <div className="absolute left-0 top-10 flex gap-4 z-10">
              <div className="flex-shrink-0">
                <img src={backButton} alt="Atrás" />
              </div>
            </div>
          </div>

          <div className="google_map container">
            <FollowMap
              latitude={cordinates?.location?._latitude}
              longitude={cordinates?.location?._longitude}
              directionsLatitude={direction?.lat}
              directionsLongitude={direction?.lng}
            />
          </div>
        </div>
      ) : (
        <div className="container relative white_bg_effect">
          <BackBtn
            backName="SOS de emergencia"
            backLink="/home"
            backImg={menuButton}
          />

          <div onClick={() => setShow(false)} className="google_map container">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              options={{
                styles: googleStyle,
              }}
              // googleStyle={{
              //   styles: googleStyle,
              // }}
              mapTypeId="roadmap"
              zoom={17}
              center={position}
            >
              <MarkerF
                position={position}
                icon={{
                  url: LocationIcon,
                  scaledSize: new window.google.maps.Size(80, 80),
                }}
              />
            </GoogleMap>
          </div>

          <div className="absolute left-0 right-0 bottom-0 mx-0 lg:mx-10 z-10">
            <div className="flex justify-center  gap-1 mb-1">
              <button
                onClick={() => setShow(true)}
                className={`border border-black rounded-full bg-white ${
                  show && "hidden"
                }`}
              >
                <img className="h-[35px] w-[35px]" src={UpArrow} alt="flecha" />
              </button>
            </div>

            {show ? (
              <div className="h-[400px] bg-[#7258F9] rounded-t-xl gap-2 overflow-scroll px-2 pb-2 flex flex-col transition duration-700 ease-in-out">
                <div className="flex    rounded-t-xl   ">
                  <div className=" py-3  absolute w-full flex  bg-[#7258F9] justify-center right-0 left-0 rounded-l-2xl rounded-r-2xl ">
                    <button
                      onClick={handlePolice}
                      className={`px-3 py-1 rounded-l-2xl rounded-r-2xl font-normal ${
                        policeData
                          ? "text-[#6D74CE] bg-gray-200 transition duration-700 ease-in-out"
                          : "text-white  bg-[#6D74CE]"
                      }`}
                    >
                      Policía
                    </button>
                    <button
                      onClick={handleLgbtq}
                      className={`px-3 py-1 rounded-l-2xl rounded-r-2xl font-normal ${
                        !policeData
                          ? "text-[#6D74CE] bg-gray-200 transition duration-700 ease-in-out"
                          : "text-white bg-[#6D74CE]"
                      }`}
                    >
                      LGTBQ
                    </button>
                  </div>
                </div>

                <div className="mt-[16%] md:mt-[10%] lg:mt-[8%] flex flex-col gap-3">
                  {policeData
                    ? data?.police_near_me &&
                      data?.police_near_me?.map((i) => {
                        return (
                          <div className="bg-gray-200 px-3 py-1 rounded-md flex flex-col">
                            <p className="text-black font-normal">{i.name}</p>
                            <span className="text-black font-light">
                              {i.vicinity}
                            </span>
                            <button
                              onClick={() =>
                                handleDirection(
                                  i?.geometry?.location?.lat,
                                  i?.geometry?.location?.lng
                                )
                              }
                              className="bg-[#7258F9] px-2 h-[30px] w-[40px] rounded-md py-1 text-white"
                            >
                              <img src={NavigateIcon} alt="" />
                            </button>
                          </div>
                        );
                      })
                    : data?.lgbtq_friendly &&
                      data?.lgbtq_friendly?.map((i) => {
                        return (
                          <div className="bg-gray-200 px-3 py-1 rounded-md flex flex-col">
                            <p className="text-black font-normal">{i.name}</p>
                            <span className="text-black font-light">
                              {i.vicinity}
                            </span>
                            <button
                              onClick={() =>
                                handleDirection(
                                  i?.geometry?.location?.lat,
                                  i?.geometry?.location?.lng
                                )
                              }
                              className="bg-[#7258F9] px-2 h-[30px] w-[40px] rounded-md py-1 text-white"
                            >
                              <img src={NavigateIcon} alt="" />{" "}
                            </button>
                          </div>
                        );
                      })}{" "}
                </div>
              </div>
            ) : (
              ""
            )}

            <SecurityMsg alertMsg="Su ubicación en vivo se comparte con la empresa de seguridad" />

            <div
              className="mx-8"
              onClick={() =>
                StopSOS({
                  final_destination: user?.destination,
                  uid: user?.uid,
                  latitude:
                    // 23.071298202877863,
                    cordinates?.location?._latitude.toString(),
                  longitude:
                    // 72.51780074021997,
                    cordinates?.location?._longitude.toString(),
                  // type:2
                })
              }
            >
              {isLoading ? (
                <button
                  type="submit"
                  className="flex w-full items-center justify-center gap-3 bg-red p-5 pb-8 rounded-t-[43px] border-4 border-white border-b-0"
                >
                  <ButtonLoader />
                </button>
              ) : (
                <SOSbtn sostitle="Detener SOS" />
              )}
            </div>
          </div>
          <div className="room ">
            {isConnected ? (
              <div className="user-list">
                <div className="user">
                  <LocalUser
                    // audioTrack={localMicrophoneTrack}
                    cameraOn={cameraOn}
                    micOn={micOn}
                    videoTrack={localCameraTrack}
                    cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                  >
                    <samp className="user-name">Tú</samp>
                  </LocalUser>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {isConnected && (
            <div className="control">
              <div className="left-control">
                <button className="btn" onClick={() => setMic((a) => !a)}>
                  <i className={`i-microphone ${!micOn ? "off" : ""}`} />
                </button>
                <button className="btn" onClick={() => setCamera((a) => !a)}>
                  <i className={`i-camera ${!cameraOn ? "off" : ""}`} />
                </button>
              </div>
              <button
                className={`btn btn-phone ${calling ? "btn-phone-active" : ""}`}
                onClick={() => setCalling((a) => !a)}
              >
                {calling ? (
                  <i className="i-phone-hangup" />
                ) : (
                  <i className="i-mdi-phone" />
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Emergency;

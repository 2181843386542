import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import LocationIcon from "../../assets/images/blueicon.png";
import { useMutation } from "@tanstack/react-query";
import { UpdateLocationAPI } from "../../api/updateLocation";
import { useAuthStore } from "../../store/auth";
import { useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

const libraries = ["places"];
const mapContainerStyle = {
  height: "100vh",
};

const googleStyle = [
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#d3d3d3",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        color: "#808080",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#b3b3b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        weight: 1.8,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d7d7d7",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ebebeb",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#a7a7a7",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#efefef",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#696969",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#737373",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#d6d6d6",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {},
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
];

const GoogleMapSec = () => {
  const { user, setCordinates } = useAuthStore();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [position, setPosition] = useState({
    lat: user?.locationDestination?._latitude,
    lng: user?.locationDestination?._longitude,
  });

  const { mutate } = useMutation(UpdateLocationAPI, {
    onSuccess: (data) => {
      if (data?.status) {
        setCordinates(data?.data);
      }
    },
    onError: (data) => {
      Notiflix.Notify.failure(
        "No estamos obteniendo sus datos de ubicación, intente restablecer el permiso de su navegador"
      );
      console.log(data, "Error");
    },
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (geoPosition) {
        // console.log(geoPosition, "geolocation");
        const { latitude, longitude } = geoPosition.coords;

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        console.log(position, "position Google");

        setShow(true);
        const data = {
          uid: user?.uid,
          latitude: latitude,
          longitude: longitude,
          location_update_at: geoPosition?.timestamp,
          location_timezone: timezone,
        };

        setPosition({
          lat: latitude,
          lng: longitude,
        });

        mutate(data);
      });
    } else {
      Notiflix.Notify.failure(
        "La geolocalización no está disponible en su navegador"
      );
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  console.log(position, "position");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDEMcchGaq5u9_6pRylftzvliVrm2KW0Qw",
    libraries,
  });

  if (loadError) {
    return <div>Error al cargar mapas</div>;
  }

  if (!isLoaded) {
    return <div>Cargando mapas</div>;
  }

  return (
    <div className="google_map container">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        options={{
          styles: googleStyle,
        }}
        mapTypeId="terrain"
        zoom={17}
        center={position}
      >
        {show && (
          <MarkerF
            position={position}
            icon={{
              url: LocationIcon,
              scaledSize: new window.google.maps.Size(80, 80),
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapSec;

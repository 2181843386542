import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Authentication from "../Pages/Authentication";
import CreateSquad from "../Pages/CreateSquad";
import Emergency from "../Pages/EmergencySoS";
import Error404 from "../Pages/Error404";
import FollowRide from "../Pages/FollowRide";
import HomeSection from "../Pages/HomeSection";
import MySquad from "../Pages/MySquad";
import OtpVerify from "../Pages/OtpVerify";
import ShareCode from "../Pages/ShareCode";
import { PrivateRouterOutlet } from "./Privateroutes";
import { PublicRouterOutlet } from "./Publicroutes";
import Login from "../Pages/Login";
import DriverInfo from "../Pages/DriverInfo";

const RouterConfig = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/">
          <Route path="/error" element={<Error404 />} />
          <Route path="*" element={<Error404 />} />
          <Route element={<PrivateRouterOutlet />}>
            <Route path="/home" element={<HomeSection />} />
            <Route path="/emergency-sos" element={<Emergency />} />
            <Route path="/follow-my-ride" element={<FollowRide />} />
            <Route path="/my-squad" element={<MySquad />} />
            <Route path="/create-squad" element={<CreateSquad />} />
            <Route path="/share-code/:id" element={<ShareCode />} />
            <Route path="/otp-verify" element={<OtpVerify />} />
          </Route>
          <Route element={<PublicRouterOutlet />}>
            <Route path="/:id" element={<Login />} />
            <Route path="/register" element={<Authentication />} />
            <Route path="/driver" element={<DriverInfo />} />
          </Route>
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default RouterConfig;

const BackBtn = (props) => {
  return (
    <div className="relative">
      <div className="absolute left-0 top-10 flex gap-4 z-10">
        <a href={props.backLink} className="flex-shrink-0">
          <img src={props.backImg} alt="Atrás" className="" />
        </a>
        <div className="mt-9">
          <label className="text-xl font-bold mb-2 inline-block">
            {props.backName}
          </label>
          <p className="opacity-40 text-sm font-medium">{props.backDesc}</p>
        </div>
      </div>
    </div>
  );
};

export default BackBtn;

const SecurityMsg = (props) => {
    return(
        <div className="text-center">
            <div className="inline-flex items-center justify-center px-5 py-2 bg-[#EAE9F3] mx-8 rounded-[43px] gap-3 mb-4">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0">
                    <path d="M4.43478 8.06903C6.44618 4.50991 7.45187 2.73034 8.83194 2.27226C9.59111 2.02025 10.4089 2.02025 11.1681 2.27226C12.5482 2.73034 13.5539 4.50991 15.5653 8.06903C17.5767 11.6282 18.5824 13.4077 18.2807 14.8577C18.1147 15.6554 17.7058 16.379 17.1125 16.9247C16.0342 17.9166 14.0229 17.9166 10 17.9166C5.97723 17.9166 3.96583 17.9166 2.88749 16.9247C2.29426 16.379 1.88535 15.6554 1.71937 14.8577C1.41768 13.4077 2.42338 11.6282 4.43478 8.06903Z" stroke="#0E0538" stroke-width="1.5"/>
                    <path d="M10.2019 14.1667V10.8333C10.2019 10.4405 10.2019 10.2441 10.0798 10.122C9.95778 10 9.76136 10 9.36853 10" stroke="#0E0538" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.99335 7.5H10.0008" stroke="#0E0538" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <label className="block text-start opacity-60 font-semibold text-xs">{props.alertMsg}</label>
            </div>
        </div>
    )
};

export default SecurityMsg;
import { request } from "../utilities/request";

export const SOSstopAPI = async (body) => {
  const response = await request({
    url: "api/sos/stop",
    method: "POST",
    body,
  });
  return response;
};

export const SOSNotifyAPI = async (body) => {
  const response = await request({
    url: "api/sos/sos-notify",
    method: "POST",
    body,
  });
  return response;
};

export const LiveStreamApi = async (body) => {
  const response = await request({
    url: "api/other/update-live-stream-bambuser",
    method: "POST",
    body,
  });
  return response;
};

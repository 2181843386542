import axios from "axios";
import { useAuthStore } from "../store/auth";

let authToken = useAuthStore.getState().token;
let removeUser = useAuthStore.getState().removeAll;
useAuthStore.subscribe((state) => {
  //subscribe to token change and updating it
  authToken = state.token;
});

axios.interceptors.request.use(
  function (config) {
    if (!config.headers) {
      config.headers = {};
    }
    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    // config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    config.headers["api_key"] =
      "base64:qA2sb9DVdCpqSjv8cYtk+ivRyst30xe2f6Xf00VIPNg=";

    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response.data;
    // console.log(response, "responseresponseresponse");
    // // Any status code that lie within the range of 2xx cause this function to trigger
    // if (response?.headers["content-type"]?.includes("application/json")) {
    //   // console.log(response, "sdfsd");
    //   response = response.data;
    //   if (response.status || response.success) {
    //     return response;
    //   } else {
    //     return Promise.reject(response);
    //   }
    // } else if (response.status === 200) {
    //   return response;
    // }

    // return Promise.reject(response);
  },

  function (error) {
    // console.log(error, "dfgfdgfdgfdgf");

    if (error?.response?.status === 401 || error?.response?.status === 403) {
      // UseToast("Session Exprired! Please Login");
      removeUser();
      // window.location.replace("/error");
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const message =
      error.response?.status === 404
        ? error?.response?.data?.message
        : "Something went wrong";
    return Promise.reject(message);
  }
);

export const request = async ({
  url,
  method = "GET",
  params,
  body,
  headers,
  responseType,
}) => {
  const BASE_URL =
    "https://us-central1-ursafe-cba93.cloudfunctions.net/api_v2/";

  const res = await axios.request({
    url: BASE_URL + url,
    method,
    params,
    data: body,
    headers,
    responseType,
  });

  return res;
};

import React from "react";
import "./App.css";
import RouterConfig from "./router/router";



const App = () => {
  return (
    <div className="">
      <RouterConfig />
    </div>
  );
};

export default App;

import { useLocation, useParams } from "react-router-dom";
import BackBtn from "../Components/BackBtn/BackBtn";
import FormBtn from "../Components/FormBtn/FormBtn";
import { backButton, shareIcon } from "../assets/images";
import { useQuery } from "@tanstack/react-query";
import { ShareSquadAPI } from "../api/squad";
import { useState } from "react";

const ShareCode = () => {
  const { id } = useParams();
  console.log(id);
  const [code, setCode] = useState("");
  const { isLoading } = useQuery(
    ["ShareSquadAPI"],
    () => {
      return ShareSquadAPI({
        id,
      });
    },
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        setCode(data?.data?.referralCode);
      },
      onError: (error) => {
        console.log(error, "error");
      },
    }
  );

  return (
    <div className="loader_background">
      <div className="container relative">
        <BackBtn backLink="/my-squad" backImg={backButton} />
        <div className="mx-6 pt-44 h-screen flex flex-col justify-between pb-5 custom_design">
          <div className="text-center">
            <h2 className="text-2xl font-bold">Compartir código</h2>
            <p className="opacity-60">
              Comparte este código de invitación con las personas que quieras en
              tu equipo
            </p>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center w-auto mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                width="50"
                height="50"
                className="block bg-transparent"
              >
                <g>
                  <circle
                    cx="50"
                    cy="50"
                    fill="none"
                    stroke="#7258F9"
                    stroke-width="10"
                    r="35"
                    stroke-dasharray="164.93361431346415 56.97787143782138"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      repeatCount="indefinite"
                      dur="1s"
                      values="0 50 50;360 50 50"
                      keyTimes="0;1"
                    ></animateTransform>
                  </circle>
                  <g></g>
                </g>
              </svg>
            </div>
          ) : (
            <label className="block text-center text-5xl font-bold text-[#7258F9] py-4">
              {code}
            </label>
          )}

          <FormBtn
            formBtnLable="Compartir código de equipo"
            btnIcon={shareIcon}
            redirectPage={(event) => (window.location.href = "/my-squad")}
          />
        </div>
      </div>
    </div>
  );
};
export default ShareCode;

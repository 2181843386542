import { useMutation, useQuery } from "@tanstack/react-query";
import { Autocomplete, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import PhoneNumberValidator, {
  countries,
} from "../utilities/PhoneNumberValidator";
import LoaderTitle from "../Components/LoaderTitle/LoaderTitle";
import "../Components/LoaderSection/style.css";
import Notiflix from "notiflix";
import { LoginAPI, RegisterAPI, VerifyIdAPI } from "../api/auth";
import { useAuthStore } from "../store/auth";
import BackBtn from "../Components/BackBtn/BackBtn";
import { loaderGif, loaderIcon, menuButton } from "../assets/images";

const Login = () => {
  const navigate = useNavigate();
  const { setUser, setSecurityId, setContactDetail, securityId } =
    useAuthStore();
  const params = useParams();

  const [position, setPosition] = useState({
    lat: "",
    lng: "",
  });

  const { isLoading } = useQuery(
    ["VerifyIdAPI"],
    () => {
      return VerifyIdAPI({
        security_id: params.id,
      });
    },
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        if (data?.status) {
          setSecurityId({
            id: params.id,
            number: data?.data?.phone,
            Subscription_Key:data?.data?.api_key
          });
          Notiflix.Notify.success("Identificación de seguridad verificada");
        } else {
          navigate("/error");
          Notiflix.Notify.failure("El ID de seguridad no coincide");
        }
      },
      onError: (error) => {
        console.log(error, "error");
        navigate("/error");
      },
    }
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (geoPosition) {
      const { latitude, longitude } = geoPosition.coords;

      setPosition({
        lat: latitude,
        lng: longitude,
      });
    });
  }, []);

  const { isLoading: LoginLoading, mutate } = useMutation(LoginAPI, {
    onSuccess: (data) => {
      if (data?.status) {
        setUser(data?.data);
        navigate("/home");
      } else {
        setContactDetail({
          code: values.countryCode?.phone,
          phone: values.phoneNumber,
          latitude: position?.lat.toString(),
          longitude: position?.lng.toString(),
        });
        navigate("/register");
      }
    },
    onError: (data) => {
      console.log(data, "Error");
      navigate("/error");
    },
  });

  const validationSchema = Yup.object().shape({
    // firstName: Yup.string().required("firstName is required"),
    // lastName: Yup.string().required("lastName is required"),
    phoneNumber: Yup.string()
      .required("Se requiere número de teléfono")
      .test("isValidNumber", "Numero de celular invalido", function (value) {
        // Use PhoneNumberValidator to check if the number is valid
        const iso = values.countryCode?.code;

        const isValid = PhoneNumberValidator(value, iso);

        if (isValid) {
          return true; // The number is valid
        } else {
          return this.createError({
            path: "phoneNumber",
            message: "Has introducido un número de móvil incorrecto.",
          });
        }
      }),
    countryCode: Yup.object().shape({
      code: Yup.string().required("Por favor elija un código de país"),
      label: Yup.string().required("Por favor elija un código de país"),
      phone: Yup.string().required("Por favor elija un código de país"),
    }),
    // email: Yup.string().email().required("email is required"),
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      phoneNumber: "",
      countryCode: { code: "", label: "", phone: "" },
    },
    validationSchema,

    onSubmit: (values) => {
      if (!position.lat || !position.lng) {
        Notiflix.Notify.failure(
          "Se requiere información de geolocalización. Permita el acceso al permiso de su ubicación en la configuración de su navegador."
        );

        return;
      }

      const data = {
        phone: `+${values.countryCode?.phone}${values.phoneNumber}`,
        latitude: position?.lat.toString(),
        longitude: position?.lng.toString(),
        security_id: securityId?.id,
        security_contact_number: securityId?.number,
      };

      mutate(data);
      //   console.log(data, "values");
    },
  });

  return (
    <div className="loader_background">
      {isLoading || LoginLoading ? (
        <div className="container">
          <div className="relative h-dvh grid place-items-center">
            <div className="relative">
              <img src={loaderIcon} alt="loader Image" className="" />
              <img
                src={loaderGif}
                alt="loader Gif"
                className="absolute w-28 h-28 left-2 right-0 mx-auto top-[-32px]"
              />
            </div>
            <div className="absolute left-0 right-0 mx-auto bottom-24">
              <LoaderTitle loadertitle="Autenticación..." loaderdesc="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <form onSubmit={handleSubmit} className="container relative">
            <BackBtn
              backName="Acceso"
              backLink={`/${securityId}`}
              backImg={menuButton}
              backDesc=""
            />
            <div className="mx-6 pt-44 h-screen flex flex-col pb-5 custom_design">
              <div className="pb-4">
                <div className="select_field mb-10">
                  <Autocomplete
                    id="country-customized-option-demo"
                    // disableCloseOnSelect
                    options={countries}
                    name="countryCode"
                    onChange={(e, newValue) => {
                      setFieldValue("countryCode.code", newValue?.code);
                      setFieldValue("countryCode.label", newValue?.label);
                      setFieldValue("countryCode.phone", newValue?.phone);
                    }}
                    autoHighlight
                    disableClearable
                    getOptionLabel={(option) =>
                      `${option.label} +${option.phone}`
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.label} +{option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Código de país"
                        name="countryCode"
                        value={values.countryCode.phone}
                      />
                    )}
                  />
                  {touched.countryCode && errors.countryCode ? (
                    <div className="text-red mt-1 text-[12px] pl-3">
                      {errors.countryCode?.phone}
                    </div>
                  ) : null}
                </div>

                <div className="mb-10">
                  <TextField
                    value={values.phoneNumber}
                    type="tel"
                    id="outlined-multiline-flexible"
                    name="phoneNumber"
                    label="Ingresa número telefónico"
                    placeholder="Ingresa número telefónico"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    focused
                    className="w-full"
                  />
                  {touched.phoneNumber && errors.phoneNumber ? (
                    <div className="text-red mt-1 text-[12px] pl-3">
                      {errors.phoneNumber}
                    </div>
                  ) : null}
                </div>
              </div>

              <button
                type="submit"
                className="w-full lg:w-80 flex items-center p-2 py-3 rounded-[29px] bg-[#7258F9] gap-2 mx-auto"
              >
                <div className="text-white w-full text-center">Acceso</div>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;

import { request } from "../utilities/request";

export const UpdateLocationAPI = async (body) => {
  // console.log(body, "ddjkjn");

  const response = await request({
    url: "api/user/update-location",
    method: "POST",
    body,
  });
  return response;
};

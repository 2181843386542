const FormBtn =(props)=>{
    return(
        <button type="submit" className="w-full lg:w-80 flex items-center p-2 rounded-[29px] bg-[#7258F9] gap-2 mx-auto" onClick={props.redirectPage}>
            <div className=" w-12 h-12 rounded-full bg-darkBlue flex items-center justify-center flex-shrink-0">
                <img src={props.btnIcon} alt="sígueme" className="" />
            </div>
            <div className="text-white w-full text-center">{props.formBtnLable}</div>
        </button>
    )
}

export default FormBtn;
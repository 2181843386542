import React, { useState } from "react";
import "./style.css";
import { GetAllSquadAPI } from "../../api/squad";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../store/auth";
import { NavLink, useNavigate } from "react-router-dom";

const SquadItem = (props) => {
  const [squadData, setSquadData] = useState([]);
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { isLoading } = useQuery(
    ["GetAllSquadAPI"],
    () => {
      return GetAllSquadAPI({
        uid: user.uid,
      });
    },
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        // console.log(data.data, "dndsk");

        setSquadData(data.data);
      },
      onError: (error) => {
        console.log(error, "error");
      },
    }
  );

  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center h-[400px] w-auto my-auto mx-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            width="70"
            height="70"
            className="block bg-transparent"
          >
            <g>
              <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#7258F9"
                stroke-width="10"
                r="35"
                stroke-dasharray="164.93361431346415 56.97787143782138"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  repeatCount="indefinite"
                  dur="1s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                ></animateTransform>
              </circle>
              <g></g>
            </g>
          </svg>
        </div>
      ) : (
        <>
          {squadData && squadData.length > 0 ? (
            squadData.map((i) => (
              <NavLink
                to={`/share-code/${i?.id}`}
                className="flex items-center justify-between px-5 py-6 rounded-3xl bg-[#F4F2FF] mb-3"
              >
                <div className="flex items-center avatar_group">
                  <div className="w-12 h-12 flex items-center justify-center bg-[#7258F9] sb-avatar--src sb-avatar__image text-white rounded-full text-lg font-medium">
                    {i?.memberCount}
                  </div>
                </div>
                <div>
                  <div className="font-bold">{i?.name}</div>
                  <span className=" opacity-40 text-xs">
                    {i?.memberCount} Miembros
                  </span>
                </div>
              </NavLink>
            ))
          ) : (
            <div className="text-2xl h-[400px] content-center text-center">
              Datos no encontrados
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SquadItem;

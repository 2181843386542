import { request } from "../utilities/request";

export const RegisterAPI = async (body) => {
  const response = await request({
    url: "user/register",
    method: "POST",
    body,
  });
  return response;
};

export const LoginAPI = async (body) => {
  const response = await request({
    url: "user/login",
    method: "POST",
    body,
  });
  return response;
};

export const VerifyIdAPI = async (body) => {
  const response = await request({
    url: "user/checkSecurityId",
    method: "POST",
    body,
  });
  return response;
};

import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
function PhoneNumberValidator(number, code) {
  // console.log(number, code);
  const phoneNumValidation =
    isPossiblePhoneNumber(number, code) && isValidPhoneNumber(number, code);
  return phoneNumValidation;
}

export default PhoneNumberValidator;

export const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  { code: "AE", label: "Emiratos Árabes Unidos", phone: "971" },
  { code: "AF", label: "Afganistán", phone: "93" },
  { code: "AG", label: "Antigua y Barbuda", phone: "1-268" },
  { code: "AI", label: "Anguila", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antártida", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "Samoa Americana", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  { code: "AU", label: "Australia", phone: "61", suggested: true },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Islas Åland", phone: "358" },
  { code: "AZ", label: "Azerbaiyán", phone: "994" },
  { code: "BA", label: "Bosnia y Herzegovina", phone: "387" },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladés", phone: "880" },
  { code: "BE", label: "Bélgica", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Baréin", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benín", phone: "229" },
  { code: "BL", label: "San Bartolomé", phone: "590" },
  { code: "BM", label: "Bermudas", phone: "1-441" },
  { code: "BN", label: "Brunéi Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brasil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bután", phone: "975" },
  { code: "BV", label: "Isla Bouvet", phone: "47" },
  { code: "BW", label: "Botsuana", phone: "267" },
  { code: "BY", label: "Bielorrusia", phone: "375" },
  { code: "BZ", label: "Belice", phone: "501" },
  { code: "CA", label: "Canadá", phone: "1", suggested: true },
  { code: "CC", label: "Islas Cocos (Keeling)", phone: "61" },
  { code: "CD", label: "República Democrática del Congo", phone: "243" },
  { code: "CF", label: "República Centroafricana", phone: "236" },
  { code: "CG", label: "República del Congo", phone: "242" },
  { code: "CH", label: "Suiza", phone: "41" },
  { code: "CI", label: "Costa de Marfil", phone: "225" },
  { code: "CK", label: "Islas Cook", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Camerún", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cabo Verde", phone: "238" },
  { code: "CW", label: "Curazao", phone: "599" },
  { code: "CX", label: "Isla de Navidad", phone: "61" },
  { code: "CY", label: "Chipre", phone: "357" },
  { code: "CZ", label: "República Checa", phone: "420" },
  { code: "DE", label: "Alemania", phone: "49", suggested: true },
  { code: "DJ", label: "Yibuti", phone: "253" },
  { code: "DK", label: "Dinamarca", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  { code: "DO", label: "República Dominicana", phone: "1-809" },
  { code: "DZ", label: "Argelia", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egipto", phone: "20" },
  { code: "EH", label: "Sahara Occidental", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "España", phone: "34" },
  { code: "ET", label: "Etiopía", phone: "251" },
  { code: "FI", label: "Finlandia", phone: "358" },
  { code: "FJ", label: "Fiyi", phone: "679" },
  { code: "FK", label: "Islas Malvinas", phone: "500" },
  { code: "FM", label: "Micronesia, Estados Federados de", phone: "691" },
  { code: "FO", label: "Islas Feroe", phone: "298" },
  { code: "FR", label: "Francia", phone: "33", suggested: true },
  { code: "GA", label: "Gabón", phone: "241" },
  { code: "GB", label: "Reino Unido", phone: "44" },
  { code: "GD", label: "Granada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "Guayana Francesa", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Groenlandia", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadalupe", phone: "590" },
  { code: "GQ", label: "Guinea Ecuatorial", phone: "240" },
  { code: "GR", label: "Grecia", phone: "30" },
  {
    code: "GS",
    label: "Islas Georgias del Sur y Sandwich del Sur",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bisáu", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  { code: "HM", label: "Islas Heard y McDonald", phone: "672" },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croacia", phone: "385" },
  { code: "HT", label: "Haití", phone: "509" },
  { code: "HU", label: "Hungría", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Irlanda", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isla de Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  { code: "IO", label: "Territorio Británico del Océano Índico", phone: "246" },
  { code: "IQ", label: "Irak", phone: "964" },
  { code: "IR", label: "Irán", phone: "98" },
  { code: "IS", label: "Islandia", phone: "354" },
  { code: "IT", label: "Italia", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordania", phone: "962" },
  { code: "JP", label: "Japón", phone: "81", suggested: true },
  { code: "KE", label: "Kenia", phone: "254" },
  { code: "KG", label: "Kirguistán", phone: "996" },
  { code: "KH", label: "Camboya", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoras", phone: "269" },
  { code: "KN", label: "San Cristóbal y Nieves", phone: "1-869" },
  { code: "KP", label: "Corea del Norte", phone: "850" },
  { code: "KR", label: "Corea del Sur", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Islas Caimán", phone: "1-345" },
  { code: "KZ", label: "Kazajistán", phone: "7" },
  { code: "LA", label: "Laos", phone: "856" },
  { code: "LB", label: "Líbano", phone: "961" },
  { code: "LC", label: "Santa Lucía", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesoto", phone: "266" },
  { code: "LT", label: "Lituania", phone: "370" },
  { code: "LU", label: "Luxemburgo", phone: "352" },
  { code: "LV", label: "Letonia", phone: "371" },
  { code: "LY", label: "Libia", phone: "218" },
  { code: "MA", label: "Marruecos", phone: "212" },
  { code: "MC", label: "Mónaco", phone: "377" },
  { code: "MD", label: "Moldavia", phone: "373" },
  { code: "ME", label: "Montenegro", phone: "382" },
  { code: "MF", label: "San Martín (parte francesa)", phone: "590" },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Islas Marshall", phone: "692" },
  { code: "MK", label: "Macedonia del Norte", phone: "389" },
  { code: "ML", label: "Malí", phone: "223" },
  { code: "MM", label: "Birmania", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  { code: "MP", label: "Islas Marianas del Norte", phone: "1-670" },
  { code: "MQ", label: "Martinica", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauricio", phone: "230" },
  { code: "MV", label: "Maldivas", phone: "960" },
  { code: "MW", label: "Malaui", phone: "265" },
  { code: "MX", label: "México", phone: "52" },
  { code: "MY", label: "Malasia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "Nueva Caledonia", phone: "687" },
  { code: "NE", label: "Níger", phone: "227" },
  { code: "NF", label: "Isla Norfolk", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Países Bajos", phone: "31" },
  { code: "NO", label: "Noruega", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "Nueva Zelanda", phone: "64" },
  { code: "OM", label: "Omán", phone: "968" },
  { code: "PA", label: "Panamá", phone: "507" },
  { code: "PE", label: "Perú", phone: "51" },
  { code: "PF", label: "Polinesia Francesa", phone: "689" },
  { code: "PG", label: "Papúa Nueva Guinea", phone: "675" },
  { code: "PH", label: "Filipinas", phone: "63" },
  { code: "PK", label: "Pakistán", phone: "92" },
  { code: "PL", label: "Polonia", phone: "48" },
  { code: "PM", label: "San Pedro y Miquelón", phone: "508" },
  { code: "PN", label: "Islas Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palaos", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Catar", phone: "974" },
  { code: "RE", label: "Reunión", phone: "262" },
  { code: "RO", label: "Rumania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Rusia", phone: "7" },
  { code: "RW", label: "Ruanda", phone: "250" },
  { code: "SA", label: "Arabia Saudita", phone: "966" },
  { code: "SB", label: "Islas Salomón", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudán", phone: "249" },
  { code: "SE", label: "Suecia", phone: "46" },
  { code: "SG", label: "Singapur", phone: "65" },
  { code: "SH", label: "Santa Helena", phone: "290" },
  { code: "SI", label: "Eslovenia", phone: "386" },
  { code: "SJ", label: "Svalbard y Jan Mayen", phone: "47" },
  { code: "SK", label: "Eslovaquia", phone: "421" },
  { code: "SL", label: "Sierra Leona", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Surinam", phone: "597" },
  { code: "SS", label: "Sudán del Sur", phone: "211" },
  { code: "ST", label: "Santo Tomé y Príncipe", phone: "239" },
  { code: "SV", label: "El Salvador", phone: "503" },
  { code: "SX", label: "Sint Maarten", phone: "1-721" },
  { code: "SY", label: "Siria", phone: "963" },
  { code: "SZ", label: "Suazilandia", phone: "268" },
  { code: "TC", label: "Islas Turcas y Caicos", phone: "1-649" },
  { code: "TD", label: "Chad", phone: "235" },
  { code: "TF", label: "Territorios Australes Franceses", phone: "262" },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Tailandia", phone: "66" },
  { code: "TJ", label: "Tayikistán", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistán", phone: "993" },
  { code: "TN", label: "Túnez", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turquía", phone: "90" },
  { code: "TT", label: "Trinidad y Tobago", phone: "1-868" },
  { code: "TV", label: "Tuvalu", phone: "688" },
  { code: "TZ", label: "Tanzania", phone: "255" },
  { code: "UA", label: "Ucrania", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  { code: "US", label: "Estados Unidos", phone: "1", suggested: true },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistán", phone: "998" },
  { code: "VA", label: "Ciudad del Vaticano", phone: "39-06" },
  { code: "VC", label: "San Vicente y las Granadinas", phone: "1-784" },
  { code: "VE", label: "Venezuela", phone: "58" },
  { code: "VG", label: "Islas Vírgenes Británicas", phone: "1-284" },
  { code: "VI", label: "Islas Vírgenes de los EE. UU.", phone: "1-340" },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis y Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "Sudáfrica", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabue", phone: "263" },
];

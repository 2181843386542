import React from "react";
import Logo from "../assets/images/logo.jpg";
import AboutUs from "../assets/images/what_we_do_phone.jpg";
import Services from "../assets/images/track_me_feature.jpg";
import Apple from "../assets/images/downloadApple.svg";
import Play from "../assets/images/downloadGoogle.svg";

const DriverInfo = () => {
  return (
    <div>
      <nav className="bg-[#7258f9] py-2 flex justify-center mb-2">
        <img src={Logo} alt="logo" />
      </nav>     
      <div className="flex justify-center items-center gap-4 px-4"> 
        <div className="flex-1">
          <img src={AboutUs} alt="AboutUs" />
        </div>
        <div className="flex-1">    
          <p className="text-[#7258f9] font-medium">Quiénes somos</p>
          <h1 className="text-xl font-medium pb-2">Qué hacemos</h1>
          <p className="text-sm leading-tight">
            Con funciones integradas como la respuesta manos libres al 911,
            seguimiento en tiempo real, alerta de llamada falsa y transmisión de 
            audio y vídeo en directo de audio y vídeo en directo, ahora se puede 
            avisar fácilmente a los seres queridos o cuando se sientan en
            peligro, con un solo clic.
          </p>       
        </div>
      </div>

      <div className="flex justify-center items-center gap-4 px-4 my-5">
        <div className="flex-1">
          <p className="text-[#7258f9] font-medium">Servicios</p>
          <h1 className="text-xl font-medium pb-2">Función Track Me</h1>
          <p className="text-sm leading-tight">
            Utilice la función TrackMe de UrSafe para controlar en tiempo real
            la ubicación de sus empleados o familiares.
          </p>
        </div>
        <div className="flex-1">
          <img src={Services} alt="Services" className="h-auto w-32" />
        </div>
      </div>

      <h1 className="text-xl font-medium text-center pb-5">
        Obtener la aplicación UrSafe
      </h1>
      <div className="flex justify-center items-center gap-3 mb-2">
        <a href="https://play.google.com/store/apps/details?id=com.ursafe&pli=1">
          <img src={Play} alt="Play" className="h-auto w-36" />
        </a>
        <a href="https://apps.apple.com/in/app/the-ursafe-app/id1481482887">
          <img src={Apple} alt="Apple" className="h-auto w-36" />
        </a>
      </div>
    </div>
  );
};

export default DriverInfo;
